.NavMenu {
}

.dropdownMenu {
  color: var(--color);
  background-color: var(--bg);
  &:focus-within {
    border: none !important;
    outline: none !important;
  }
  &:focus {
    border: none !important;
    outline: none !important;
  }
  &:focus-visible {
    border: none !important;
    outline: none !important;
  }
  .itemNav {
    &:hover {
      color: var(--bg);
      background-color: var(--color);
    }
  }
}

.navigationMenu {
  color: var(--color);
  background-color: var(--bg);
}

.navMenu {
  color: var(--color);
  background-color: var(--bg);
  height: calc(100vh);
  top:70px;
}


.socialIcon {
  @apply w-[2rem] h-[2rem];
  background-color: var(--color);
  background-size: contain;
  -webkit-mask: var(--svg) no-repeat center;
  mask: var(--svg) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.socialIconDefault {
  @apply w-[2rem] h-[2rem];
  background-image: var(--svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}